import Contador from './Items/Contador.jsx'
import Tabla from './Items/Tabla.jsx'

const Escolaridad = () => {
  return (
    <div>
      <Contador/>
      <br/>
      <br/>
      <Tabla/>
    </div>
  )
}

export default Escolaridad
