import React from 'react'

const Configuracion = () => {
  return (
    <div>
        <h1 className="text-3xl font-light mb-4">desde Configuracion</h1>
     
    </div>
  )
}

export default Configuracion
