import { useState, useEffect } from 'react';
import { Card, Flex, Metric, Grid,Col, Button, Subtitle } from "@tremor/react";

const Contador = () => {

  const [count, setCount] = useState(0);
  const [paidCount, setPaidCount] = useState(0);
  const [unpaidCount, setUnpaidCount] = useState(0);

  useEffect(() => {
    fetch('https://asijeminapis.website:4548/api/escolaridad/')
      .then(response => response.json())
      .then(data => {
        let tempCount = 0;
        let tempPaidCount = 0;
        let tempUnpaidCount = 0;

        data.forEach(item => {
          for (let i = 1; i <= 7; i++) {
            const na = item[`na${i}`];
            if (na.nombre.trim() !== "" && na.p.toLowerCase() === "hijo") {
              tempCount++;
              if (item.pagado === true) {
                tempPaidCount++;
              } else if (item.pagado === false) {
                tempUnpaidCount++;
              }
            }
          }
        });

        setCount(tempCount);
        setPaidCount(tempPaidCount);
        setUnpaidCount(tempUnpaidCount);
      })
      .catch(error => console.error('Error:', error));
  }, []);

const totalesBecas= count * 25000;

  return (
    <div className='mt-6'>
       
    <div className="mb-10">
            <p className=' text-left font-light text-2xl text-blue-900 ml-6 pt-1 border-b-2 border-indigo-500'>Totales Escolaridad</p>
    </div>

    <Grid  numItemsSm={2} numItemsLg={4} className="gap-2" gapX='gap-x-5'>
        <Col numColSpan={1} numColSpanLg={1}>

            <Card className='max-w-sm'>
                <p className="text-center mb-2 font-light text-base text-white">Total Solicitudes</p>
                <Flex>
                        <Subtitle>Total </Subtitle>
                        <Metric >{count}</Metric>                            
                </Flex>
                <Button className=' mt-6 w-full'  variant="secondary">ir al listado</Button>
               
            </Card>

        </Col>
        <Card className='max-w-sm'>
                 <p className="text-center mb-2 font-light text-base text-white">Total Pagas</p>
                 <Flex>
                        <Subtitle>Total </Subtitle>
                        <Metric>{paidCount}</Metric>
                           
                </Flex>
                <Button className=' mt-6 w-full'  variant="secondary">ir al listado</Button>
               
            </Card>

            <Card className='max-w-sm'>
                 <p className="text-center mb-2 font-light text-base text-white">Total a Pagar</p>
                 <Flex>
                        <Subtitle>Total </Subtitle>
                        <Metric>{unpaidCount}</Metric>                </Flex>
                <Button className=' mt-6 w-full'  variant="secondary">ir al listado</Button>
               
            </Card>

            <Card className='max-w-sm'>
                 <p className="text-center mb-2 font-light text-base text-white">Monto a Pagar</p>
                 <Flex>
                        <Subtitle>Total </Subtitle>
                        <Metric>{totalesBecas}</Metric>                </Flex>
                <Button className=' mt-6 w-full'  variant="secondary">ir al listado</Button>
               
            </Card>

          
    </Grid>



</div>
  )
}

export default Contador
