
const Comision = () => {
    return (
      <div>
         <h1 className="text-3xl font-light mb-4">desde comision</h1>
      </div>
    )
  }
  
  export default Comision
  