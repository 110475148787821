import { NavLink } from 'react-router-dom'
import './UI.css'
const UI = () => {
  return (
    <div className='md:w-2/5 xl:w-1/5 bg-gray-800'> 
        <div className='p-6'>
            <p className=' text-white text-2xl tracking-wide text-center font-bold'>GESSindical</p>
            
            <div className='border-2 border-gray-800 border-t-indigo-500 border-b-indigo-500 mt-5'>
                <p className='  mt-2 mb-2 text-gray-400'>cosas iconos</p>
            </div>

            <nav className='mt-10'>
                <NavLink exact to="/" className="p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900 ml-3"> Dashboard</NavLink>

                <NavLink exact to="/afiliados" className="p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900 ml-3" >  Afiliados</NavLink>
                
                <NavLink exact to="/regional" className="p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900 ml-3">Regional</NavLink>

                <NavLink exact to="/comision" className="p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900 ml-3">Comision</NavLink>

                <div className='mt-8'>
                    <p className='text-white mt-6'>Accion Social</p>
                </div>
                <NavLink exact to="/escolaridad" className="p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900 ml-3">Escolaridad</NavLink>

                <NavLink exact to="/vinculos" className="p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900 ml-3">Vinculos</NavLink>

                <NavLink exact to="/confasocial" className="p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900 ml-3">Configuracion</NavLink>


                <div className='mt-8'>
                    <p className='text-white mt-6'>Administracion</p>
                </div>
                <NavLink exact to="/configuracion" className="p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900 ml-3">Configuracion</NavLink>

                <NavLink exact to="/usuarios" className="p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900 ml-3">Usuarios</NavLink>

            </nav>
        </div>
    </div>
  )
}

export default UI
