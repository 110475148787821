import React from 'react'

const ConfSocial = () => {
  return (
    <div>
         <h1 className="text-3xl font-light mb-4">desde Configuracion aSocial</h1>
      
    </div>
  )
}

export default ConfSocial
