import { Col, Grid,Flex, Metric, Text, Title, Divider,Legend, Card ,BarChart, Subtitle, DonutChart } from '@tremor/react'
import React from 'react'

const GraficosA = () => {

    const chartdata2 = [
        {
          name: "Topic 1",
          "Group A": 890,
          "Group B": 338,
          "Group C": 538,
          "Group D": 396,
          "Group E": 138,
          "Group F": 436,
        },
        {
          name: "Topic 2",
          "Group A": 289,
          "Group B": 233,
          "Group C": 253,
          "Group D": 333,
          "Group E": 133,
          "Group F": 533,
        },
      ];
      
      const dataFormatter = (number: number) => {
        return "$ " + Intl.NumberFormat("us").format(number).toString();
      };
      

// data para el donut
const cities = [
    {
      name: "New York",
      sales: 9800,
    },
    {
      name: "London",
      sales: 4567,
    },
    {
      name: "Hong Kong",
      sales: 3908,
    },
    {
      name: "San Francisco",
      sales: 2400,
    },
    {
      name: "Singapore",
      sales: 1908,
    },
    {
      name: "Zurich",
      sales: 1398,
    },
  ];

  const valueFormatter = (number: number) => `$ ${Intl.NumberFormat("us").format(number).toString()}`;

  


  return (
    <>
    <Grid numItemsSm={2} numItemsLg={2} className="gap-2 mt-12" gapX='gap-x-5'>
        <Col numColSpan={1} numColSpanLg={1} >
            <Card >
                <Title>Grafico por Estado</Title>
                <BarChart
                    className="mt-6"
                    data={chartdata2}
                    index="name"
                    
                    colors={["blue", "teal", "amber", "rose", "indigo", "emerald"]}
                    valueFormatter={dataFormatter}
                    yAxisWidth={48}
                    categories={["Group A", "Group B", "Group C", "Group D", "Group E", "Group F"]}
                    />
            </Card>            
        </Col>


        <Card >
                <Title>Grafico por Tipo</Title>
                <DonutChart
                className="mt-6"
                data={cities}
                category="sales"
                index="name"
                valueFormatter={valueFormatter}
                colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
                variant='pie variant'
                />
        <Subtitle>dato1</Subtitle>
        </Card>  
        
    </Grid>
      
    </>
  )
}

export default GraficosA
