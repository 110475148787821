import { Card, Flex, Metric, Grid,Col, Button, Subtitle } from "@tremor/react";

const Totales = () => {
  return (
    <div className='mt-6'>
       
        <div className="mb-10">
                <p className=' text-left font-light text-2xl text-blue-900 ml-6 pt-1 border-b-2 border-indigo-500'>Totales Afiliados</p>
        </div>

        <Grid  numItemsSm={2} numItemsLg={4} className="gap-2" gapX='gap-x-5'>
            <Col numColSpan={1} numColSpanLg={1}>

                <Card className='max-w-sm'>
                    <p className="text-center mb-2 font-light text-base text-white">Total Afiliados Cotizantes de Alta</p>
                    <Flex>
                            <Subtitle>Total </Subtitle>
                            <Metric >46</Metric>                            
                    </Flex>
                    <Button className=' mt-6 w-full'  variant="secondary">ir al listado</Button>
                   
                </Card>

            </Col>
            <Card className='max-w-sm'>
                     <p className="text-center mb-2 font-light text-base text-white">Afiliados Mensuales</p>
                     <Flex>
                            <Subtitle>Total </Subtitle>
                            <Metric >46</Metric>                            
                    </Flex>
                    <Button className=' mt-6 w-full'  variant="secondary">ir al listado</Button>
                   
                </Card>

                <Card className='max-w-sm'>
                     <p className="text-center mb-2 font-light text-base text-white">Afiliados Masculinos</p>
                     <Flex>
                            <Subtitle>Total </Subtitle>
                            <Metric >46</Metric>                            
                    </Flex>
                    <Button className=' mt-6 w-full'  variant="secondary">ir al listado</Button>
                   
                </Card>

                <Card className='max-w-sm'>
                     <p className="text-center mb-2 font-light text-base text-white">Afiliados Femeninos</p>
                     <Flex>
                            <Subtitle>Total </Subtitle>
                            <Metric >46</Metric>                            
                    </Flex>
                    <Button className=' mt-6 w-full'  variant="secondary">ir al listado</Button>
                   
                </Card>
        </Grid>



    </div>
  )
}

export default Totales
