import React from 'react'
import { Card, Flex, Metric, Text, Title, Divider, Grid,Col,CategoryBar, Legend, Button } from "@tremor/react";

const CardsRegional = () => {
  return (
    <div>
        <Grid  numItemsSm={2} numItemsLg={3} className="gap-2" gapX='gap-x-5'>
            <Col numColSpan={1} numColSpanLg={1} >
            <Card className='max-w-sm'>
            <Title className=" text-center mb-2 font-light">Datos Regional Sur</Title>
            <Flex>
            <Text>Afiliados</Text>
            <Metric>155</Metric>
            </Flex>

            <Divider />
            <CategoryBar className="mt-4" values={[20, 135]} colors={[ "pink", "blue"]} />
                <Legend
                className="mt-3"
                categories={[ `Femenino 20`, "Masculino 135"]}
                colors={["pink", "blue" ]}
                />
             <Button  className=' mt-6 w-full'>Ir al Listado</Button>
            </Card>
            </Col>


            <Card className='max-w-sm'>
            <Title className=" text-center mb-2 font-light">Datos Regional Norte</Title>
            <Flex>
            <Text>Afiliados</Text>
            <Metric>155</Metric>
            </Flex>

            <Divider />
            <CategoryBar className="mt-4" values={[20, 135]} colors={[ "pink", "blue"]} />
                <Legend
                className="mt-3"
                categories={[ `Femenino 20`, "Masculino 135"]}
                colors={["pink", "blue" ]}
                />
            <Button  className=' mt-6 w-full'>Ir al Listado</Button>


            </Card>
           
            
           
            <Card className='max-w-sm'>
            <Title className=" text-center mb-2 font-light">Datos Regional Centro</Title>
            <Flex>
            <Text>Afiliados</Text>
            <Metric>155</Metric>
            </Flex>

            <Divider />
            <CategoryBar className="mt-4" values={[20, 135]} colors={[ "pink", "blue"]} />
                <Legend
                className="mt-3"
                categories={[ `Femenino 20`, "Masculino 135"]}
                colors={["pink", "blue" ]}
                />
              <Button  className=' mt-6 w-full'>Ir al Listado</Button>



            </Card>
            
        </Grid>


            
        
    </div>
  )
}

export default CardsRegional
