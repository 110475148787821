import { useState, useEffect } from 'react';
import './estilos.css';
import './Tabla.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faDollarSign, faEye } from '@fortawesome/free-solid-svg-icons'
import * as XLSX from 'xlsx';
import Modal from 'react-modal';

Modal.setAppElement('#root'); 



const Tabla = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('https://asijeminapis.website:4548/api/escolaridad/')
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error:', error));
  }, []);

  const countChildren = (item) => {
    let count = 0;
    for (let i = 1; i <= 7; i++) {
      const child = item[`na${i}`];
      if (child && child.edad >= 4 && child.edad <= 26) {
        count++;
      }
    }
    return count;
  };

  const calculateAge = (birthday) => {
    const ageDifferenceMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifferenceMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  };

  const hasChildOver18 = (item) => {
    for (let i = 1; i <= 7; i++) {
      const child = item[`na${i}`];
      if (child && child.p === "Hijo" && child.fn !== "0000-00-00" && calculateAge(child.fn) > 19) {
        return true;
      }
    }
    return false;
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [childData, setChildData] = useState(null);

  const openModal = (childData) => {
    setChildData(childData);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePayment = (dni, pagado) => {
    fetch(`https://asijeminapis.website:4548/api/escolaridad/${dni}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pagado: !pagado }),
    })
    .then(response => response.json())
    .then(() => {
      // Hacemos una copia del estado actual de los datos
      const newData = [...data];
      // Encontramos el índice del afiliado que hemos actualizado
      const index = newData.findIndex(item => item.dni === dni);
      // Actualizamos el estado de 'pagado' del afiliado
      newData[index].pagado = !pagado;
      // Establecemos el estado de los datos con la copia actualizada
      setData(newData);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const exportToExcel = () => {
    
      const dataToExport = data.map(item => ({
        afiliado: item.afiliado,
        nombre: item.nombre,
        dni: item.dni,
        cantidadHijos: countChildren(item),
        certificado: hasChildOver18(item) ? 'Sí' : 'No',
        estadoDelPago: item.pagado ? 'Pagado' : 'A pagar'
      }));
    
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "escolaridad.xlsx");
  };


  return (
    <>

      <div className="mb-10">
        <p className=" text-left font-light text-2xl text-blue-900 ml-6 pt-1 border-b-2 border-indigo-500">
          Listado Solicitudes
        </p>

      </div>
      <div className='containerButton'>
      <button onClick={exportToExcel}>Exportar</button>

      </div>

      <table class="hover:table-fixed md:table-fixed w-full" id='tbsolicitud'>        
        <thead>
          <tr>
            <th>N° Afiliado</th>
            <th>Nombre</th>
            <th>DNI</th>
            <th>Cantidad Hijos</th>
            <th>Certificado</th>
            <th>Estado del Pago</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {data.map((item, index) => (
  <tr key={index}>
    <td>{item.afiliado}</td>
    <td>{item.nombre}</td>
    <td>{item.dni}</td>
    <td>{countChildren(item)}</td>
    <td className='iconPay'> {hasChildOver18(item) && <FontAwesomeIcon icon={faCircle} />}</td>
    <td className={item.pagado ? 'bg-green-500' : 'bg-red-500'}>
      {item.pagado ? 'Pagado' : 'A pagar'}
    </td>
    <td className='iconOpt'> 
     
    <button className="btnDetalle" onClick={() => openModal(item)}>
  <FontAwesomeIcon icon={faEye} className='eyeIcon'/>
</button>



      <button className="btnDetalle" onClick={() => handlePayment(item.dni, item.pagado)}>
        <FontAwesomeIcon icon={faDollarSign}/>  
      </button>
    </td>
  </tr>
))}
        </tbody>
      </table>

   
      <Modal
  className={"modal-familia"}
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  contentLabel="Child Data"
>
  {childData && (
    <table className='tbmodal  table-striped'>
      <thead>
        <tr>
          <th className='thnombre'>Nombre</th>
          <th className='thedad'>Edad</th>
          <th>Certificado</th>
        </tr>
      </thead>
      <tbody>
  {[1, 2, 3, 4, 5, 6, 7].map(i => {
    const child = childData[`na${i}`];
    const age = calculateAge(child.fn);
    if (child && child.p === "Hijo" && child.fn !== "0000-00-00" && age >= 4 && age <= 26) {
      return (
        <tr>
          <td>{child.nombre}</td>
          <td className='thedad'>{age}</td>
          <td className='thcertificado'>{age > 20 && <FontAwesomeIcon icon={faCircle} />}</td>
        </tr>
      );
    }
  })}
</tbody>
    </table>
  )}
  <button onClick={closeModal} className='btnmodalclose'>Cerrar</button>
</Modal>


    </>
  );
};

export default Tabla;