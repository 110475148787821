import React from 'react'

const Usuarios = () => {
  return (
    <div>
              <h1 className="text-3xl font-light mb-4">desde Usuarios</h1>

    </div>
  )
}

export default Usuarios
