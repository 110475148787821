import CardsRegional from "../DashboardItems/CardsRegional/CardsRegional"
import GraficosA from "../DashboardItems/GraficosA/GraficosA"
import Totales from "../DashboardItems/Totales/Totales"

const Dashboard = () => {
  return (
    <div >
        <p className=' text-3xl font-light mb-8 text-blue-900 '>Dashboard</p>
       <CardsRegional/>
       <Totales/>
       <GraficosA/>
        
    </div>
  )
}

export default Dashboard
