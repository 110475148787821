import React from 'react'

const Vinculos = () => {
  return (
    <div>
               <h1 className="text-3xl font-light mb-4">desde vinculos</h1>

    </div>
  )
}

export default Vinculos
