import React from 'react'

const Regional = () => {
  return (
    <div>
          <p className=' text-3xl font-light mb-4'>desde regional</p>
    </div>
  )
}

export default Regional
