import React from 'react'

const Afiliados = () => {
  return (
    <div >
      <p className=' text-3xl font-light mb-4'>desde afiliados</p>
    </div>
  )
}

export default Afiliados
