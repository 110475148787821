import {Routes, Route} from 'react-router'


import UI from "./components/UI/UI";
import Dashboard from './components/Dashboard/Dashboard';
import Afiliados from './components/Afiliados/Afiliados';
import Regional from './components/Regional/Regional';
import Comision from './components/Comision/Comision';
import Vinculos from './components/Vinculos/Vinculos';
import ConfSocial from './components/ConfSocial/ConfSocial';
import Configuracion from './components/Configuracion/Configuracion';
import Usuarios from './components/Usuarios/Usuarios';
import Escolaridad from './components/Escolaridad/Escolaridad';

function App() {
  return (
    <div className="md:flex min-h-screen">
      
     <UI/>
     <div className='md:w-3/5 xl:w-4/5 p-6 bg-gray-300'>
     <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/afiliados" element={<Afiliados />} />
          <Route path="/regional" element={<Regional/>} />
          <Route path="/comision" element={<Comision/>} />
          <Route path="/vinculos" element={<Vinculos/>} />
          <Route path="/confasocial" element={<ConfSocial/>} />
          <Route path="/configuracion" element={<Configuracion/>} />
          <Route path="/usuarios" element={<Usuarios/>} />
          <Route path="/escolaridad" element={<Escolaridad/>} />

     </Routes>
     </div>
    </div>
  );
}

export default App;


